import React, { ChangeEvent } from "react";
import { Dispatch } from "redux";
import { get, upload } from "../service/request";
import { ImportError, ImportStatsResult } from "../types/import-types";
import { SubmitProperties, UploadFormData } from "../types/upload-types";
import { CLEAR_DOCUMENT_TYPE, SET_DOCUMENT_TYPE } from "../store/actions";
import { AbortError, detectDocumentType, FileResult } from "../utils/dopix";
import { resetFileField } from './file-upload-area';

/**
 * File change event callback function that is called when the user changes
 * the file to upload for import or conflict check.
 * @param setUploadProgress callback function that sets the upload progress.
 * @param setUploadCompleted callback function that set if the upload is complete
 * @param setDocumentType callback function that sets a document type
 * @param setDocumentTypeError callback function that sets a document error
 * @param dispatch upload button view dispatch
 * @param setImportError callback function that set an import error
 * @param setFormData callback function that set the form data
 * @param formData form data for file upload
 */
export const onFileChange = (
    setUploadProgress: (value: React.SetStateAction<number | null>) => void,
    setUploadCompleted: (value: React.SetStateAction<boolean>) => void,
    setDocumentType: (value: React.SetStateAction<FileResult | null>) => void,
    setDocumentTypeError: (value: React.SetStateAction<boolean>) => void,
    dispatch: Dispatch,
    setImportError: (value: React.SetStateAction<ImportError | null>) => void,
    setFormData: (value: React.SetStateAction<UploadFormData>) => void,
    formData: UploadFormData,
) => async (fileChangeEvent: ChangeEvent<HTMLInputElement>) => {
    if (fileChangeEvent.target.files) {
        setImportError(null);
        setUploadProgress(null);
        setUploadCompleted(false);
        setFormData({
            ...formData,
            files: fileChangeEvent.target.files,
        });

        try {
            const detectedDocumentType = await detectDocumentType(
                fileChangeEvent.target.files[0]);
            setDocumentType(detectedDocumentType);
            setDocumentTypeError(false);
            dispatch({
                type: SET_DOCUMENT_TYPE,
                payload: {
                    documentType: detectedDocumentType,
                },
            });
        } catch (e: any) {
            setDocumentType(null);
            if (e instanceof AbortError) {
                setDocumentTypeError(false);
            } else {
                setDocumentTypeError(true);
            }

            dispatch({
                type: CLEAR_DOCUMENT_TYPE,
            });
        }
    }
};

/**
 * Handles submit action when user clicked on button 'Weiter'
 * on import page.
 * @param setUploadProgress
 * @param setUploadCompleted
 * @param onComplete
 * @param setImportError
 * @param fileRef
 * @param formData
 * @param setDocumentType
 * @param dispatch
 * @param setShowError
 */
export const onSubmitHome = async (
    {
        setUploadProgress,
        setUploadCompleted,
        onComplete,
        setImportError,
        fileRef,
        formData,
        setDocumentType,
        dispatch,
        setShowError,
    }: SubmitProperties,
) => {
    const newFormData = new FormData();

    if (formData.instance) {
        newFormData.set('instanceName', formData.instance);
    }
    if (formData.tenant) {
        newFormData.set('tenantName', formData.tenant);
    }
    newFormData.set(
        'overwriteExistingElements',
        formData.overwriteExistingElements ? 'true' : 'false',
    );
    if (formData.documentEntryPointIds) {
        newFormData.set('documentEntryPointIds', formData.documentEntryPointIds);
        /*  if documentEntryPointIds is an array of strings, then we cannot simply
            set it. instead we must do the following:
            newFormData.append('documentEntryPointIds', formData.documentEntryPointIds[i]);
         */
    }
    if (formData.files?.length) {
        newFormData.set('file', formData.files[0]);
    }

    if (formData.ccmSystem) {
        newFormData.set('ccmSystem', formData.ccmSystem);
    }


    const file: File | null | undefined = formData.files?.item(0);
    let couldFindId: boolean = false;
    await file?.text().then(
        fileContent => {
            if (fileContent.includes(` id="${formData.documentEntryPointIds}"`)) {
                couldFindId = true;
            }
        }
    )

    if (!couldFindId) {
        setShowError(true);
        return;
    }


    try {
        const result = (await upload('/import', {
            formData: newFormData,
            onProgress: fileUploadProgress(
                setUploadProgress,
                setUploadCompleted,
            ),
        })) as ImportStatsResult;

        setImportError(null);

        dispatch({
            type: SET_DOCUMENT_TYPE,
            payload: {
                stats: result,
            },
        });

        onComplete({
            homeImportResult: {
                result: result,
            },
        });
    } catch (error: any) {
        console.error(error);
        if (error instanceof ImportError) {
            setImportError(error);
        } else {
            console.log("error was an unexpected error!")
            // check if health endpoint of backend is reachable
            try {
                const healthCheckResult = (
                    await get<String>('/healthz')
                );

                console.log("health check successful. " +
                    "Result: " + healthCheckResult
                )

                setImportError({
                    name: 'Unerwarteter Import error',
                    message: 'Leider ist ein unbekannter Fehler aufgetreten. ' +
                        'Technischer Fehler:\n' + error,
                } as ImportError);
            } catch (e) {
                setImportError({
                    name: 'Import während der Ausführung abgebrochen!',
                    message: 'Das backend ist nicht mehr erreichbar. Bitte ' +
                        'versuchen Sie es in ein paar Minuten erneut.',
                } as ImportError);
                console.log("health check not possible. error...")
                console.log(e);
            }
        }
        resetFileField(fileRef, setDocumentType);
    }
};

/**
 * Handles the file upload progress bar.
 * @param setUploadProgress
 * @param setUploadCompleted
 */
export const fileUploadProgress = (
    setUploadProgress: (value: React.SetStateAction<number | null>) => void,
    setUploadCompleted: (value: React.SetStateAction<boolean>) => void,
) => (percentCompleted: number) => {
    setUploadProgress(percentCompleted);
    if (percentCompleted === 100) {
        setUploadCompleted(true);
    }
};