import {
    ImportCheckResult,
    ImportCheckResultDiffed,
    ImportError,
    ImportStatsResult,
    ImportVariable
} from "./import-types";
import React from "react";
import { Upload } from "../components/upload/Upload";
import { Input } from "semantic-ui-react";
import { FileResult } from "../utils/dopix";
import { Dispatch } from "redux";


export type UploadData = {
    homeImportResult?: ImportCheckResult<ImportStatsResult>,
    conflictCheckImportResult?: ImportCheckResultDiffed<ImportVariable>
};

export type UploadCompleteHandler = (data: UploadData) => void;

export interface UploadCompleteHandlerProvider {
    onComplete: UploadCompleteHandler
}

export interface UploadApi extends UploadCompleteHandlerProvider {
    submitCallback: (submitProperties: SubmitProperties) => void;
    showOverwriteToggle: boolean;
}

export interface UploadFormStep extends UploadApi {
    data: UploadData;
}
export interface UploadFormData {
    files?: FileList;
    instance?: string;
    overwriteExistingElements?: boolean;
    tenant?: string;
    documentEntryPointIds?: string;
    ccmSystem?: string;
}

export interface SubmitProperties {
    setUploadProgress: (value: React.SetStateAction<number | null>) => void,
    setUploadCompleted: (value: React.SetStateAction<boolean>) => void,
    onComplete: UploadCompleteHandler,
    setImportError: (value: React.SetStateAction<ImportError | null>) => void,
    fileRef: React.RefObject<Input>,
    formData: UploadFormData,
    setDocumentType: (value: React.SetStateAction<FileResult | null>) => void,
    dispatch: Dispatch,
    setShowError: (value: React.SetStateAction<boolean>) => void,
}

export abstract class AbstractStep {
    constructor(
        public icon: string,
        public title: string,
        public description: string,
        public nextStep: AbstractStep | undefined,
    ) {}
    public abstract render(submitButtonCallback: (submitProperties: SubmitProperties) => void,
                    showOverwriteToggle: boolean,
                    data: UploadData,
                    api: UploadApi): JSX.Element;
}

export class UploadStep extends AbstractStep {
    public render(submitButtonCallback: (submitProperties: SubmitProperties) => void,
           showOverwriteToggle: boolean,
           data: UploadData,
           api:UploadApi): JSX.Element {
        return <Upload {...api} data={data}
        submitCallback={submitButtonCallback}
        showOverwriteToggle={showOverwriteToggle} />;
    }
}

export const uploadStep = new UploadStep(
    'upload',
    'Upload',
    'Datei hochladen',
    undefined
);