import { setProperty } from "./helpers";
import { ImportError } from "../types/import-types";

export type BaseFileResult = {
  fileName: string;
  type: 'dopix';
};

export class DopixFileResult implements BaseFileResult {
    releaseId?: number;
    buildId?: number;
    fixLevel?: number;
    releaseDate?: string;
    dsfVersion?: string;
    timestamp?: string;
    type: 'dopix' = 'dopix';

    constructor(public fileName: string) {}

    private parseNumberOrNull(regExpMatchArray: RegExpMatchArray | null): number | null {
        return (regExpMatchArray) ? parseInt(regExpMatchArray[1], 10) : null
    }
    private getStringOrNull(matchIdx: number,
                            regExpMatchArray: RegExpMatchArray | null): string | null {
        return (regExpMatchArray) ? regExpMatchArray[matchIdx] : null
    }
    
    public setReleaseIdBy(releaseIdMatch: RegExpMatchArray | null): DopixFileResult {
        return setProperty(this, 'releaseId', this.parseNumberOrNull(releaseIdMatch));
    }
    public setBuildIdBy(buildIdMatch: RegExpMatchArray | null): DopixFileResult {
        return setProperty(this, 'buildId', this.parseNumberOrNull(buildIdMatch));
    }
    public setFixLevelBy(fixLevelMatch: RegExpMatchArray | null): DopixFileResult {
        return setProperty(this, 'fixLevel', this.parseNumberOrNull(fixLevelMatch));
    }
    public setReleaseDateBy(releaseDateMatch: RegExpMatchArray | null): DopixFileResult {
        return setProperty(this, 'releaseDate', this.getStringOrNull(1, releaseDateMatch));
    }
    public setDsfVersionBy(dataMatch: RegExpMatchArray | null): DopixFileResult {
        return setProperty(this, 'dsfVersion', this.getStringOrNull(1, dataMatch));
    }
    public setTimeStampBy(dataMatch: RegExpMatchArray | null): DopixFileResult {
        return setProperty(this, 'timestamp', this.getStringOrNull(2, dataMatch));
    }

}

export type FileResult = DopixFileResult | undefined;

export const isDopixFileResult = (input: any): input is DopixFileResult => {
  return (
    input instanceof DopixFileResult &&
    input.type === 'dopix'
  );
};

export const detectDocumentType = async (file: File): Promise<FileResult> => {
  return new Promise<FileResult>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target!.result as string;
      if (/<dope /.test(content)) {
        const result = getDopixFileInfo(content, file);
        return resolve(result);
      }
      return reject(new IllegalFileTypeError('Invalid document type'));
    };
    reader.onabort = () => {
      reject(new AbortError(`Reading file "${file.name}" aborted`));
    };
    if (file === undefined) {
      reject(new AbortError('No file selected'));
    }

    reader.readAsText(file);
  });
};

/**
 * Retrieves DOPiX-system details from the provided file and
 * returns a DopixFileResult.
 *
 * @param fileContent
 * @param file
 *
 * @return a DopixFileResult
 */
function getDopixFileInfo(fileContent: string, file: File): DopixFileResult {
    const releaseIdMatch = fileContent.match(/<releaseId>(\d+)<\/releaseId>/);
    const buildIdMatch = fileContent.match(/<buildId>(\d+)<\/buildId>/);
    const fixLevelMatch = fileContent.match(/<fixLevel>(\d+)<\/fixLevel>/);
    const releaseDateMatch = fileContent.match(
      /<releaseDate>([\d-]+)<\/releaseDate>/
    );
    const dataMatch = fileContent.match(
      /<data dbtype="DRP" version="(.*?)" [\s\S]* timestamp="(.*?)" [\s\S]*>[\s\S]*<\/data>/m
    );

    return new DopixFileResult(file.name)
        .setReleaseIdBy(releaseIdMatch)
        .setBuildIdBy(buildIdMatch)
        .setFixLevelBy(fixLevelMatch)
        .setReleaseDateBy(releaseDateMatch)
        .setDsfVersionBy(dataMatch)
        .setTimeStampBy(dataMatch);

}

/**
 * This error indicates that the selected file is of the wrong type.
 */
export class IllegalFileTypeError extends ImportError {}

/**
 * This error indicates that the file selection was aborted.
 */
export class AbortError extends ImportError {}
