import React, { useEffect, useRef, useState } from "react";
import { UploadFormData, UploadFormStep } from "../../types/upload-types";
import { useDispatch } from "react-redux";
import {
    Button,
    Checkbox,
    Confirm,
    Dropdown,
    DropdownItemProps,
    Form,
    Input,
    Loader,
    Message,
    Modal,
    Progress as ProgressBar
} from "semantic-ui-react";
import { Instance } from "../../service/api";
import { ImportError } from "../../types/import-types";
import { FileResult, isDopixFileResult } from "../../utils/dopix";
import { fetchInstances, onInstanceChange, setupInstanceDropdown } from "../../view/fcbsuite-instance-dropdown";
import { CLEAR_DOCUMENT_TYPE } from "../../store/actions";
import { onFileChange } from "../../view/file-upload-button";
import { onTenantChange } from "../../view/fcbsuite-tenant-dropdown";
import { onDocumentEntryPointIdsChange } from "../../view/fcbsuite-entry-point-ids-input";
import { DopixDocumentInformation } from "../DopixDocumentInformation";
import WarningTriangle from '../../static/warning.svg';

import './Upload.scss'
import { onCcmSystemChange } from '../../view/fcbsuite-ccmsystem-dropdown';


export const Upload: React.FC<UploadFormStep> = (
    { onComplete, submitCallback, showOverwriteToggle }: UploadFormStep
) => {
    // FIXME: showOverwriteConfirmationWarning only used in HomeUpload

    // FIXME: wird nur in HomeUpload benutzt.
    // Used for warn-message of Overwrite-toggle.
    // Used in onFileChange.
    // Used in submitCallback
    const dispatch = useDispatch();

    // Wird im FormField fuer die Instanzen und den Mandant verwendet
    const [isLoadingInstances, setIsLoadingInstances] = useState<boolean>(true);

    // for error overlay
    const [showError, setShowError] = useState<boolean>(false);

    //  Wird im FormField fuer die Instanzen verwendet
    const [instances, setInstances] = useState<DropdownItemProps[]>([]);

    // Wird in tenantsForCurrentInstance benoetigt.
    const [rawInstances, setRawInstances] = useState<Instance[]>([]);

    // used to show error message and to decide if progress bar is shown
    // is set in fetchInstances, showOverwriteConfirmationWarning.onCancel,
    // ...,  showOverwriteConfirmationWarning.onConfirm FormField.onChange={onFileChange(...)}
    // submit-button -> callback
    // FIXME: in ConflictCheckUpload wird setImportError in der submit-callback
    //  -function benutzt.
    const [importError, setImportError] = useState<ImportError | null>(null);

    // Used in ProgressBar, submitCallback, FileChange
    // only in HomeUpload: used in showOverwriteConfirmationWarning
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);

    // Used in ProgressBar, submitCallback, FileChange
    // only in HomeUpload: used in showOverwriteConfirmationWarning
    const [uploadCompleted, setUploadCompleted] = useState<boolean>(false);

    // used in form-field success message and to disable submit button "Weiter",
    // used for form-field of field "onChange={onFileChange(...)}
    // FIXME only in ConflictCheck: used in submit-button in onSubmit-method.
    const [documentType, setDocumentType] = useState<FileResult | null>(null);

    // used for error message and to disable submit button "Weiter", used in
    // onFileChange, and in showOverwriteConfirmationWarning.onCancel(...)
    // FIXME: only in ConflictCheck: used in SubmitButton
    const [documentTypeError, setDocumentTypeError] = useState<boolean>(false);

    // Used in
    // - tenantsForCurrentInstance
    // - updateOverwriteExistingElementsState
    // - showOverwriteConfirmationWarning.onConfirm
    // - instance-DropDown.onChange, .value
    // - mandant-DropDown.disabled, .onChange, .value
    // - showOverwriteToggle.checked, .onChange
    // - onFileChange
    // - submitButton.onClick, .disabled
    //    - (in submitCallback)
    const [formData, setFormData] = useState<UploadFormData>({});

    // FIXME: only in HomeUpload
    const [showOverwriteWarning, setShowOverwriteWarning] = useState<boolean>(false);
    const [showOverwriteConfirmationWarning, setShowOverwriteConfirmationWarning] =
        useState<boolean>(false);

    // used in:
    // - showOverwriteConfirmationWarning.onConfirm
    // - file-field Input.ref
    // - submitButton.onClick --> submitCallback
    const fileRef = useRef<Input | null>(null);

    // used in mandant-dropdown.
    const tenantsForCurrentInstance = (() =>
        setupInstanceDropdown(rawInstances, formData))();

    // FIXME: only in HomeUpload
    const updateOverwriteExistingElementsState = (state: boolean) => {
        setFormData({
            ...formData,
            overwriteExistingElements: state
        });
    };


    // FIXME: this hook is called twice on start an every side load.
    //  watch https://www.youtube.com/watch?v=bGzanfKVFeU for more information
    useEffect(() => {
        // alert("fetch available docsA instances")
        // noinspection JSIgnoredPromiseFromCall
        fetchInstances(setRawInstances, setInstances, setIsLoadingInstances, setImportError)
    }, []);

    let ccmSystems = [
        { key: 'DOPIX', text: 'DOPiX', value: 'DOPIX'},
        { key: 'CARTAGO', text: 'Cartago', value: 'CARTAGO'},
    ];
    return (
        <div className="upload">
            {showOverwriteWarning ? (
                <Confirm
                    open={showOverwriteWarning}
                    onCancel={() => {
                        updateOverwriteExistingElementsState(false);
                        setShowOverwriteWarning(false);
                    }}
                    onConfirm={() => {
                        updateOverwriteExistingElementsState(true);
                        setShowOverwriteWarning(false);
                    }}
                    content="Möchten Sie wirklich überschreiben/aktualisieren?"
                />
            ) : null}
            {showOverwriteConfirmationWarning ? (
                <Confirm
                    open={showOverwriteConfirmationWarning}
                    onCancel={() => {
                        setShowOverwriteConfirmationWarning(false);
                        setDocumentTypeError(false);
                        setImportError(null);
                        dispatch({
                            type: CLEAR_DOCUMENT_TYPE
                        });
                    }}
                    onConfirm={() => {
                        setShowOverwriteConfirmationWarning(false);
                        submitCallback({
                            setUploadProgress,
                            setUploadCompleted,
                            onComplete,
                            setImportError,
                            fileRef,
                            formData,
                            setDocumentType,
                            dispatch,
                            setShowError,}
                        );
                    }}
                    content="Möchten Sie wirklich überschreibend/aktualisierend fortfahren?"
                />
            ) : null}
            {importError && (
                <Message
                    /*
                     * this attribute is hardly recommended to query
                     * this element effectively in unit tests
                     */
                    role='show-import-error-box'
                    negative visible>
                    <strong>{importError.name}</strong>
                    <p>
                        {importError.message}
                    </p>
                    <p>
                        Bitte versuchen Sie es erneut und überprüfen Sie die
                        hochgeladene Datei.
                    </p>
                </Message>
            )}

            {showError ? (
                <Modal
                    centered={false}
                    role="upload-warning"
                    open={showError}
                    onClose={() => setShowError(false)}
                    onOpen={() => setShowError(true)}
                >
                    <Modal.Header>
                        <div className="upload_warning_title">
                            <img src={WarningTriangle}
                                 alt="Warning triangle"
                                 width="3.5%"
                                 height="auto"
                            />
                            Fehler
                        </div>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            Es konnte kein Element mit id "{formData.documentEntryPointIds}"
                            in der Datei gefunden werden. Bitte die ID und die Datei
                            überprüfen...
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setShowError(false)}>OK</Button>
                    </Modal.Actions>
                </Modal>
            ) : null}

            <Form>
                {/* Dropdown field to select instance */}
                <Form.Field required>
                    <label id="instance-selection">docsA⁺ Instanz</label>
                    <Dropdown
                        /*
                         * this attribute is hardly recommended to query
                         * this element effectively in unit tests
                         */
                        aria-labelledby="instance-selection"
                        loading={isLoadingInstances}
                        placeholder="docsA⁺ Instanz"
                        fluid
                        selection
                        options={instances}
                        onChange={onInstanceChange(setFormData, formData)}
                        value={formData.instance!}
                    />
                </Form.Field>

                {/* Dropdown field to select tenant/Mandant [DE] */}
                <Form.Field required>
                    <label id="tenant-selection">Mandant</label>
                    <Dropdown
                        /*
                         * this attribute is hardly recommended to query
                         * this element effectively in unit tests
                         */
                        aria-labelledby="tenant-selection"
                        disabled={!formData.instance}
                        loading={isLoadingInstances}
                        placeholder="Mandant"
                        fluid
                        selection
                        options={tenantsForCurrentInstance}
                        onChange={onTenantChange(setFormData, formData)}
                        value={formData.tenant!}
                    />
                </Form.Field>

                {/* Shows toggle button when showOverwriteToggle === true */}
                {showOverwriteToggle ? (
                    <Form.Field id={"overwriteToggle"}>
                        <Checkbox
                            toggle
                            onClick={(e) => e.stopPropagation()}
                            checked={formData.overwriteExistingElements!}
                            onChange={() => {
                                if (!formData.overwriteExistingElements) {
                                    updateOverwriteExistingElementsState(true);
                                    setShowOverwriteWarning(true);
                                } else {
                                    updateOverwriteExistingElementsState(false);
                                }
                            }}
                            label="Überschreiben/Aktualisieren?"
                        />
                    </Form.Field>
                ) : null }

                <Form.Group>

                    {/* Dropdown field to select ccm system */}
                    <Form.Field required style={{ width: '130px', marginRight: '1rem' }}>
                            <label id="ccm-system-selection">CCM-System</label>
                            <Dropdown
                                aria-labelledby="ccm-system-selection"
                                placeholder="CCM System"
                                fluid
                                selection
                                options={ccmSystems}
                                onChange={onCcmSystemChange(setFormData, formData)}
                                value={formData.ccmSystem!}
                                style={{ height: '43.43px'}}
                                required
                            />
                    </Form.Field>

                    {/* File-upload field */}
                    <Form.Field required style={{ flexGrow: 1 }}>
                        <label id="idxx2">Export aus Dokumentendrucksystem</label>
                        <Input
                            style={{ width: '100%' }}
                            role="file-upload-button"
                            ref={fileRef}
                            type="file"
                            onChange={onFileChange(
                                setUploadProgress,
                                setUploadCompleted,
                                setDocumentType,
                                setDocumentTypeError,
                                dispatch,
                                setImportError,
                                setFormData,
                                formData,)}
                            accept="application/xml, .xml, .dsf"
                            required
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Field required>
                    <label id="document-entry-point-ids">Zu importierendes Dokument</label>
                    <input
                        name="document"
                        type="text"
                        onChange={onDocumentEntryPointIdsChange(setFormData, formData)}
                        placeholder="Id des obersten Elements des Dokumentes"
                    />
                </Form.Field>

                {/* shows (green) success message box, when valid file was selected.
                        reads file and displays meta information (written in the xml file) */}
                {documentType ? (
                    <Form.Field>
                        {isDopixFileResult(documentType) ? (
                            <Message
                                success
                                visible
                                /*
                                 * this attribute is hardly recommended to query
                                 * this element effectively in unit tests
                                 */
                                role="upload-message-box-success">
                                <DopixDocumentInformation documentType={documentType} />
                            </Message>
                        ) : null}
                    </Form.Field>
                ) : null }

                {/* display error message for invalid file selection */}
                {documentTypeError ? (
                    <Form.Field>
                        <Message negative visible>
                            <p>
                                Für diese Datei konnte das Quellsystem nicht erkannt werden.
                                Bitte überprüfen Sie die Datei.
                            </p>
                        </Message>
                    </Form.Field>
                ) : null }

                {/*  shows progressBar and RunningTask-Indicator during upload
                     and analysis */}
                {!importError && uploadProgress && uploadProgress > 0 ? (
                    <Form.Field>
                        <ProgressBar
                            percent={uploadProgress}
                            active
                            progress
                            success={uploadCompleted}
                        />
                        {uploadCompleted ? (
                            <Loader active inline content="Analysiere…" />
                        ) : null }
                    </Form.Field>
                ) : null }

                {/* Submit-Button to start action via the submitCallback */}
                <Button
                    type="submit"
                    role="submit-button"
                    onClick=
                        {() => {
                            if (formData.overwriteExistingElements) {
                                setShowOverwriteConfirmationWarning(true);
                            } else {
                                submitCallback({
                                    setUploadProgress,
                                    setUploadCompleted,
                                    onComplete,
                                    setImportError,
                                    fileRef,
                                    formData,
                                    setDocumentType,
                                    dispatch,
                                    setShowError
                                });
                            }
                        }}
                    primary
                    disabled={!(
                            formData.instance &&
                            formData.tenant &&
                            formData.documentEntryPointIds &&
                            documentType &&
                            !documentTypeError)}>
                    Weiter
                </Button>

            </Form>
        </div>

    );
};