import { upload } from "../service/request";
import { SubmitProperties } from "../types/upload-types";
import { ImportError, ImportResult } from '../types/import-types';
import { addDiffsToImportResult, extractError } from "../service/api";
import { fileUploadProgress, resetFileField } from './file-upload-area';


export const onSubmitConflictCheck = async (
    {
      setUploadProgress,
      setUploadCompleted,
      onComplete,
      setImportError,
      fileRef,
      formData,
      setDocumentType,
      dispatch,
    } : SubmitProperties
) => {
  const fd = new FormData();

  if (formData.instance) {
    fd.set('instanceName', formData.instance);
  }
  if (formData.tenant) {
    fd.set('tenantName', formData.tenant);
  }
  if (formData.files?.length) {
    fd.set('file', formData.files[0]);
  }
  if (formData.ccmSystem) {
      fd.set('ccmSystem', formData.ccmSystem);
  }

  try {
    const result = (await upload('/conflict-check', {
      formData: fd,
      onProgress: fileUploadProgress(
          setUploadProgress,
          setUploadCompleted
      ),
    })) as ImportResult;

    setImportError(null);

    onComplete({
      conflictCheckImportResult: addDiffsToImportResult(result),
    });
  } catch (e: any) {
    console.error(e);
    let extractedError: ImportError | unknown;
    if (e instanceof ImportError) {
        setImportError(e);
    } else if (ImportError.isAssignable((extractedError = extractError(e)))) {
        setImportError(extractedError as ImportError);
    } else {
        setImportError({
        name: 'Unknown conflict check error',
        timestamp: '',
        message: 'Leider ist ein unbekannter Fehler aufgetreten.',
        data: {},
      } as ImportError);
    }
    resetFileField(fileRef, setDocumentType);
  }
};